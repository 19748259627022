import React from "react";
const ratingStarActive = "https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-star-filled.svg";
const ratingStar = "https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-star.svg";

export const RatingContainer = props => {
    const {rating, hideRating = false} = props
    return <div className={"rating-container "}>
        {(!hideRating) && <span className="rating">{Number(rating).toFixed(1).replace('.0', '')}</span>}
        <img src={rating >= 1 ? ratingStarActive : ratingStar} className="rating-star" alt={1}/>
        <img src={rating >= 2 ? ratingStarActive : ratingStar} className="rating-star" alt={2}/>
        <img src={rating >= 3 ? ratingStarActive : ratingStar} className="rating-star" alt={3}/>
        <img src={rating >= 4 ? ratingStarActive : ratingStar} className="rating-star" alt={4}/>
        <img src={rating >= 5 ? ratingStarActive : ratingStar} className="rating-star" alt={5}/>
    </div>
}
