import React, {useEffect, useState} from "react";
import {SEO} from "../components/SEO";
import {ResultsList} from "../components/dashboard/ResultsList";
import axios from "axios";
import {STAGE} from "../getStage";
import {FiltersList} from "../components/dashboard/FiltersList";

const arrayCleaner = arr => arr.filter(x => (x !== 0) && x);

export const SearchRequests = props => {
    const {location} = props;
    const searchParams = new URLSearchParams(location.search)

    const reg = arrayCleaner(searchParams?.get("regions")?.split(',')?.map(Number) || []);
    const prod = arrayCleaner(searchParams?.get("sectors")?.split(',')?.map(Number) || []);
    const serv = arrayCleaner(searchParams?.get("services")?.split(',')?.map(Number) || []);

    const [state, setState] = useState({
        regions: reg,
        sectors: prod,
        services: serv
    });

    const {
        regions,
        sectors,
        services
    } = state;
    const [results, setResults] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    const search = async () => {
        if(!loading) {
            try {
                setLoading(true);
                const response = await axios.post(`https://${STAGE}.bytehogs.com/ac-m-be/requests`, JSON.stringify({
                    regions,
                    sectors,
                    services,
                    operation: "search"
                }));
                setLoaded(true);
                setLoading(false)
                if(response.status === 200) {
                    setResults(response.data.requests || []);
                    props.history.push({
                        pathname: '/requests',
                        search: "?" + new URLSearchParams({
                            regions,
                            sectors,
                            services}).toString()
                    });
                }
            } catch (e) {
                setLoaded(true);
                setLoading(false)
                console.log("e", e);
            }
        }
    }

    const handleFilterChange = (data, key) => {
        let newState = {...state};
        newState[key] = data;
        setState(newState);
    }

    useEffect(() => {
        search();
    }, [
        regions,
        sectors,
        services]);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.querySelector('body').scrollTo(0,0);
    }, []);

    return <div className="view dashboard search-partners search-rfps">
        <SEO title="Search Requests on Aesthetix Connect" slug="/rfps" />
        <div className="container">
            <div className="intro">
                <h1>Search our database of Requests</h1>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <FiltersList activeFilters={{
                        regions,
                        sectors,
                        services,
                    }}
                     handleChange={handleFilterChange}
                    requests/>
                </div>
                <div className="col-md-7 offset-md-1">
                    <ResultsList results={results} basePath="/requests/" loading={loading} request/>
                </div>
            </div>
        </div>
    </div>
}
