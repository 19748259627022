import React, {useEffect, useState} from "react";
import axios from "axios";
import {PREFIX, STAGE} from "../getStage";
import BackToDashboard from "../components/dashboard/BackToDashboard";
import BHGImage from "../components/BHGImage";
import {SEO} from "../components/SEO";
import {AwardsList} from "../components/dashboard/AwardsList";
import {TestimonialsList} from "../components/dashboard/TestimonialsList";
import {AuthPromptModal} from "../components/dashboard/AuthPromptModal";
import {RatingContainer} from "../components/RatingContainer";

export const DistributorDetails = props => {
    const {match: {params: {id}}} = props;
    const [distributor, setDistributor] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [contactOpen, setContact] = useState(false);
    const normalizedRatings = Number(distributor?.rating || 0).toFixed(1).replace('.0', '')

    const mapFilterValues = (filterType, filters) => {
        if(!window.acBaseData || JSON.stringify(window.acBaseData) === '{}') return null;
        const theValues = window.acBaseData[filterType];
        return filters.map(x => theValues.find(y => (y.id === x) && y).name).join(', ');
    }

    const leaveTestimonial = async () => {
        try {
            const response = await axios.post(`https://${STAGE}.bytehogs.com/ac-m-be/distributors`, JSON.stringify({
                distributorId: id,
                operation: "leaveTestimonial"
            }));

            if(response.status === 200) {
                window.location.href = `https://${PREFIX}clients.aesthetixconnect.com/set-testimonial/` + response.data.testimonialToken
            }
        } catch (e) {
            console.log("e", e);
        }
    }

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.post(`https://${STAGE}.bytehogs.com/ac-m-be/distributors`, JSON.stringify({
                    distributorId: id,
                    operation: "getMore"
                }));

                if (response.status === 200) {
                    setDistributor(response.data.distributor);
                }
            } catch (e) {
                console.log("e", e);
            } finally {
                setLoaded(true);
            }
        }
        getData();
    }, [id]);

    useEffect(() => {
        if(!window.acBaseData) {
            const getData = async () => {
                const response = await axios.post(`https://${STAGE}.bytehogs.com/ac-m-be/getBaseData`, JSON.stringify({}));
                window.acBaseData = response.data.baseData;
                setLoaded(false);
                setLoaded(true);
            }
            getData();
        }
        window.scrollTo(0, 0);
        document.querySelector('body').scrollTo(0,0);
    }, []);

    return <div className="view dashboard distributor-details">
        <SEO title={(distributor?.name || "Distributor") + " on Aesthetix Connect"} slug={"/distributors/" + id} description={distributor?.bio || ""} >
            {distributor && <script type="application/ld+json">{`
              {
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": "${distributor?.name || ''}",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "bestRating": "5",
                    "worstRating": "0",
                    "ratingValue": "${distributor?.rating || 0}",
                    "reviewCount": "${distributor?.testimonials?.length || 0}"
                }
              }
            `}</script>}
        </SEO>
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <BackToDashboard/>
                    {!loaded ? <div>Loading...</div>:
                        (!distributor ? <div>Distributor not found</div> : <>
                            <div className="title-bar">
                                <div>
                                    <BHGImage src={distributor.logo} className="distributor-logo"/>
                                </div>
                                <div>
                                    <h1>{distributor.name} </h1>
                                    <div className="rating"><RatingContainer rating={distributor?.rating || 0}/></div>
                                    <div className="testimonial-count">({distributor.testimonials.length} reviews)</div>
                                    <div className="actions">
                                        <button className="btn btn-secondary" onClick={() => setContact(true)}>Contact</button>
                                        <a href={distributor.website + '?ref=Aesthetix%20Connect'} rel="noopener noreferrer" target="_blank" className="btn btn-outline-secondary">Visit Website</a>
                                        <button className="btn btn-outline-secondary" onClick={leaveTestimonial}>Leave testimonial</button>
                                    </div>
                                </div>
                            </div>
                            <section className="summary">
                                <h2>Summary</h2>
                                <div className="distributor-services-card">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row  no-gutters">
                                                <div className="col-md-5">
                                                    <span className="detail-title">Services:</span>
                                                </div>
                                                <div className="col-md-7">
                                                    <span className="detail-value">{mapFilterValues( 'services', distributor.filters?.services ? distributor.filters?.services.split(',').map(Number) || [] : [])}</span>
                                                </div>
                                                <div className="col-md-5">
                                                    <span className="detail-title">Sectors:</span>
                                                </div>
                                                <div className="col-md-7">
                                                    <span className="detail-value">{mapFilterValues( 'sectors', distributor.filters?.sectors ? distributor.filters?.sectors.split(',').map(Number) || [] : [])}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="row no-gutters">

                                                <div className="col-md-5">
                                                    <span className="detail-title">Regions:</span>
                                                </div>
                                                <div className="col-md-7">
                                                    <span className="detail-value">{mapFilterValues( 'regions', distributor.filters?.regions ? distributor.filters?.regions.split(',').map(Number) || [] : [])}</span>
                                                </div>
                                                <div className="col-md-5">
                                                    <span className="detail-title">Overall rating:</span>
                                                </div>
                                                <div className="col-md-7">
                                                    <span className="detail-value">{normalizedRatings} ({distributor.testimonials.length}) reviews</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="description">
                                <h2>Company Description</h2>
                                <p>{distributor.bio}</p>
                            </section>
                            {distributor.awards.length > 0 && <section className="awards">
                                <h2>Awards</h2>
                                <AwardsList awards={distributor.awards}/>
                            </section>}
                            {distributor.testimonials.length > 0 && <section className="testimonials">
                                <h2>Reviews</h2>
                                <TestimonialsList testimonials={distributor.testimonials}/>
                            </section>}
                            <div className="bottom-actions">
                                <button className="btn btn-secondary" onClick={() => setContact(true)}>Contact</button>
                            </div>
                            <AuthPromptModal isOpen={contactOpen} base='clients' close={() => setContact(false)}/>
                        </>)}
                </div>
            </div>
        </div>
    </div>
}
