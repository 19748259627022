import React from "react"
import {Helmet} from "react-helmet";

export const SEO = props => {
    const {title, slug, description, image, creator = 'Aesthetix Connect', children} = props

    return <Helmet>
        <title>{title}</title>
        <meta name="description" content={description}/>
        <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>
        <link rel="canonical" href={"https://www.aesthetixconnect.com/" + slug}/>
        <meta property="og:locale" content="en_GB"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={description}/>
        <meta property="og:url" content={"https://www.aesthetixconnect.com/" + slug}/>
        <meta property="og:site_name" content="Aesthetix Connect"/>
        <meta property="og:image" content={image}/>
        <meta property="og:image:secure_url" content={image}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:description" content={description}/>
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:site" content="@"/>
        <meta name="twitter:image" content={image}/>
        <meta name="twitter:creator" content={"@" + creator}/>
        {children}
    </Helmet>
}
