import React, {useState} from "react";
import {RatingContainer} from "../RatingContainer";
import {TestimonialDetailsModal} from "./TestimonialDetailsModal";

export const TestimonialsList = props => {
    const [activeTestimonial, setActive] = useState(null);
    const {testimonials} = props;

    return <div className="testimonials-list">
        {testimonials.map(testimonial => {
            const {accumRating, productUsed, comments} = testimonial;
            return <div className="testimonial" onClick={() => setActive(testimonial)}>
                <RatingContainer hideRating rating={accumRating}/>
                <span className="name">{productUsed}</span>
                <p>{comments.length > 110 ? comments.substring(0, 110) + '...' : comments} <span className="read-more">Read more</span></p>
            </div>
        })}
        <TestimonialDetailsModal isOpen={activeTestimonial} close={() => setActive(null)} testimonial={activeTestimonial}/>
    </div>
}
