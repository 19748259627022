import React, {Component} from 'react'
import ReactGA from "react-ga";

export default function AnalyticsTracker(WrappedComponent, options = {}) {
    const trackPage = (page) => {
        if(process.env.NODE_ENV !== 'development') {
            ReactGA.set({
                page,
                ...options
            });
            ReactGA.pageview('/' + page);
            if(window.fbq) window.fbq('track', 'PageView');
        }
    };

    const HOC = class extends Component {
        componentDidMount() {
            const page = this.props.location.pathname;
            trackPage(page);
        }

        componentWillReceiveProps(nextProps) {
            const currentPage = this.props.location.pathname;
            const nextPage = nextProps.location.pathname;

            if (currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    return HOC;
}
