import React from "react";

export const BytehogsPill = (props) => {
  const { id, name, active, onClick, removable = false } = props;
  return (
    <div
      className={"BHG-pill" + (active ? " active" : "")}
      onClick={() => onClick(id)}
    >
      <span>{name}</span>
      {removable && (
        <img
          src="https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-remove-pill.svg"
          alt="Remove Pill"
          className="remove-icon"
        />
      )}
    </div>
  );
};
