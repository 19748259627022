import React from "react";
import {Link} from "react-router-dom";
import {Logo} from "./Logo";
import "@fortawesome/fontawesome-free/css/all.css";

export const Footer = (props) => {
  return (

      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-xl-4 order-0">
              <Logo/>
            </div>
            <div className="col-lg-5 col-xl-4 links order-lg-2">
              <Link to="/terms">Terms and Conditions</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <a href="mailto:info@aesthetixconnect.com">Support</a>
            </div>
            <div className="col-lg-2 col-xl-4 social order-lg-1">
              <a href="https://instagram.com/aesthetixconnect"><i className="fab fa-instagram"></i></a>
              <a href="https://linkedin.com/company/aesthetixconnect"><i className="fab fa-linkedin"></i></a>
              <a href="https://twitter.com/AesthetixConx"><i className="fab fa-twitter-square"></i></a>
            </div>
          </div>
        </div>
      </footer>
  );
};
