import React, {useEffect, useState} from "react";
import {FilterGroup} from "./FilterGroup";
import axios from "axios";
import {STAGE} from "../../getStage";
import Select from "react-select";
import {BytehogsPillContainer} from "./BytehogsPillContainer";
/*const requestFilterOptions = [
    { name: "Product Specialization", key: "products" },
    { name: "Business Areas", key: "business_areas" },
    { name: "Services Areas", key: "services" },
];*/

const filterOptions = [
    { name: "Sectors", key: "sectors" },
    { name: "Services Areas", key: "services" }
];

export const FiltersList = props => {
    const [loaded, setLoaded] = useState(false);
    const [open, setOpen] = useState(false);
    const {activeFilters, handleChange, requests = false} = props;
    //const filterOptions = requests ? requestFilterOptions : partnerFilterOptions;

    const loadActiveRegions = () => {
        const regions = activeFilters.regions;
        return regions ? regions.map((x) => ({ value: x })) : [];
    };

    const loadRegions = () => {
        if (!window.acBaseData || !window.acBaseData.regions) return [];
        return window.acBaseData.regions.map((x) => ({ value: x.id, label: x.name }));
    };

    const handleRegionSelect = x => {
        const values = x.map((y) => y.value) || [];
        handleChange(values, 'regions')
    }

    const removeRegion = (value) => {
        const regions = activeFilters.regions.filter((x) => x !== value && x);
        handleChange(regions, 'regions')
    };

    const handleSelectAllRegions = () => {
        const regions = window.acBaseData.regions.filter(r => (!r.parentId)).map(x => x.id);
        handleChange(regions, 'regions')
    };

    useEffect(() => {
        if(!window.acBaseData) {
            const getData = async () => {
                const response = await axios.post(`https://${STAGE}.bytehogs.com/ac-m-be/getBaseData`, JSON.stringify({}));
                window.acBaseData = response.data.baseData;
                setLoaded(true);
            }
            getData();
        }
    }, []);

    return <div className="filters-list">
        <div>
            <div className="mobile-toggle" onClick={() => setOpen(!open)}>Select Filters</div>
            <div className={"content " + (open ? "open" : "")}>
                <div className="location-group">
                    <div className="title">
                        <label>Location</label>
                        <span className="select-all" onClick={handleSelectAllRegions}>Select all</span>
                    </div>
                    <Select
                        value={loadActiveRegions()}
                        isMulti
                        hideSelectedOptions
                        controlShouldRenderValue={false}
                        backspaceRemovesValue={false}
                        className="BHG-search-select"
                        classNamePrefix="BHG-search-select"
                        placeholder=""
                        onChange={handleRegionSelect}
                        options={loadRegions()}
                    />
                    <BytehogsPillContainer
                        active={loadActiveRegions().map((x) => x.value)}
                        options={window.acBaseData?.regions || []}
                        onClick={removeRegion}
                        selectedOnly
                    />
                </div>
                {filterOptions.map(filterOp => <FilterGroup
                    title={filterOp.name}
                    options={window.acBaseData?.[filterOp.key]}
                    active={activeFilters[filterOp.key]}
                    handleChange={d => handleChange(d, filterOp.key)}/>)}
            </div>
        </div>
    </div>
}
