import React, { Component } from 'react';

export default class BHGImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            src: props.src,
            errored: false,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.src !== this.state.src) {
            this.setState({src:nextProps.src, errored: false})
        }
    }

    onError = () => {
        if (!this.state.errored) {
            this.setState({
                src: "https://client-upload.bytehogs.com/ac/media/ac-symbol-dark.png",
                errored: true,
            });
        }
    }

    render() {
        const { src } = this.state;
        const {
            src: _1,
            ...props
        } = this.props;

        return (
            <img
                src={src}
                alt=""
                onError={this.onError}
                {...props}
            />
        );
    }
}
