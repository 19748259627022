import React from "react";
import { SEO } from "../components/SEO";
import PLProfile from "../assets/images/pl-profile.svg";
import PLOpen from "../assets/images/pl-openrfp.svg";

export const Join = (props) => {
  window.scrollTo(0, 0);
  return (
    <div className="view join">
      <SEO title="Join | Aesthetix Connect" slug="join" />
      <div className="container">
        <h1>Login</h1>
        <div className="row">
          <div className="col-md-4">
            <div className="home-card">
              <img src={PLProfile}/>
              <a href="https://clients.aesthetixconnect.com" className="btn btn-primary">Login as a Client</a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="home-card">
              <img src={PLOpen}/>
              <a href="https://distributors.aesthetixconnect.com" className="btn btn-primary">Login as a Distributor</a>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};
