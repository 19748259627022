import React, {useEffect, useState} from "react";
import axios from "axios";
import {PREFIX, STAGE} from "../getStage";
import BackToDashboard from "../components/dashboard/BackToDashboard";
import {SEO} from "../components/SEO";
import {AuthPromptModal} from "../components/dashboard/AuthPromptModal";

export const RequestDetails = props => {
    const {match: {params: {id}}} = props;
    const [Request, setRequest] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [contactOpen, setContact] = useState(false);

    const mapFilterValues = (filterType, filters) => {
        if(!window.acBaseData || JSON.stringify(window.acBaseData) === '{}') return null;
        const theValues = window.acBaseData[filterType];
        return filters.map(x => theValues.find(y => (y.id === x) && y).name).join(', ');
    }

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.post(`https://${STAGE}.bytehogs.com/ac-m-be/requests`, JSON.stringify({
                    requestId: id,
                    operation: "getById"
                }));

                if (response.status === 200) {
                    setRequest(response.data.request);
                }
            } catch (e) {
                console.log("e", e);
            } finally {
                setLoaded(true);
            }
        }
        getData();
    }, [id]);

    useEffect(() => {
        if(!window.acBaseData) {
            const getData = async () => {
                const response = await axios.post(`https://${STAGE}.bytehogs.com/ac-m-be/getBaseData`, JSON.stringify({}));
                window.acBaseData = response.data.baseData;
                setLoaded(false);
                setLoaded(true);
            }
            getData();
        }
        window.scrollTo(0, 0);
        document.querySelector('body').scrollTo(0,0);
    }, []);

    return <div className="view dashboard rfp-details">
        <SEO title={"Request " + (Request?.name || "details") + " on Aesthetix Connect"} slug={"/requests/" + id} />
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <BackToDashboard backPath="/requests"/>
                    {!loaded ? <div>Loading...</div>:
                        (!Request ? <div>Request not found</div> : <>
                            <div className="title-bar">
                                <div>
                                    <h1>{Request.title}</h1>
                                    <div className="actions">
                                        <button className="btn btn-secondary" onClick={() => setContact(true)}>View More Details</button>
                                    </div>
                                </div>
                            </div>
                            <section className="summary">
                                <h2>Summary</h2>
                                <div className="distributor-services-card">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row no-gutters">
                                                <div className="col-md-5">
                                                    <span className="detail-title">Services:</span>
                                                </div>
                                                <div className="col-md-7">
                                                    <span className="detail-value">{mapFilterValues( 'services', Request.filters?.services ? Request.filters?.services || [] : [])}</span>
                                                </div>
                                                <div className="col-md-5">
                                                    <span className="detail-title">Sectors:</span>
                                                </div>
                                                <div className="col-md-7">
                                                    <span className="detail-value">{mapFilterValues( 'sectors', Request.filters?.sectors ? Request.filters?.sectors || [] : [])}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row no-gutters">
                                                <div className="col-md-5">
                                                    <span className="detail-title">Avg Expected Budget:</span>
                                                </div>
                                                <div className="col-md-7">
                                                    <span className="detail-value">{Request.expectedBudget}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <AuthPromptModal isOpen={contactOpen} base='distributors' close={() => setContact(false)}/>
                        </>)}
                </div>
            </div>
        </div>
    </div>
}
