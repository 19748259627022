import React, { Component } from "react";
import ReactGA from "react-ga";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {Header} from "./Header";
import {Footer} from "./Footer";
import {Home} from "../pages/Home";
import {Join} from "../pages/Join";
import AnalyticsTracker from "./AnalyticsTracker";
import {CookieMessage} from "./CookieMessage";
import {Terms} from "../pages/Terms";
import {Privacy} from "../pages/Privacy";
import {SearchDistributors} from "../pages/SearchDistributors";
import {DistributorDetails} from "../pages/DistributorDetails";
import {SearchRequests} from "../pages/SearchRequests";
import {RequestDetails} from "../pages/RequestDetails";

export default class Routing extends Component {
    constructor(props) {
        super(props);

        ReactGA.initialize('UA-178510820-2', {
            debug: false,
            titleCase: false
        });
    }

    render() {
        return (
            <BrowserRouter>
                <Header />
                <Switch>
                    <Route path="/" exact component={AnalyticsTracker(Home)} />
                    <Route path="/join" exact component={AnalyticsTracker(Join)} />
                    <Route path="/distributors" exact component={AnalyticsTracker(SearchDistributors)} />
                    <Route path="/distributors/:id" exact component={AnalyticsTracker(DistributorDetails)} />
                    <Route path="/requests" exact component={AnalyticsTracker(SearchRequests)} />
                    <Route path="/requests/:id" exact component={AnalyticsTracker(RequestDetails)} />
                    <Route path="/terms" exact component={AnalyticsTracker(Terms)} />
                    <Route path="/privacy-policy" exact component={AnalyticsTracker(Privacy)} />
                    <Redirect to="/" />
                </Switch>
                <Footer />
                <CookieMessage />
            </BrowserRouter>
        );
    }
}
