import React from "react";

export const AwardsList = props => {
    const {awards} = props;

    return <div className="awards-list">
        {awards.map(award => {
            const {name, received, description} = award;
            const awardDate = new Date(received);
            return <div className="award">
                <div className="award-title-bar">
                    <span className="name">{name}</span>
                    <span>{awardDate.toDateString()}</span>
                </div>
                <p>{description}</p>
            </div>
        })}
    </div>
}
