import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { Logo } from "./Logo";

export const Header = (props) => {
  let {pathname} = useLocation();
  const [scrollY, setScrollY] = useState(0);
  const [mobOpen, setOpen] = useState(false);

  const logIt = () => setScrollY(window.pageYOffset);

  useEffect(() => {
    const watchScroll = () => window.addEventListener("scroll", logIt);
    watchScroll();
    return () => window.removeEventListener("scroll", logIt);
  }, []);

  const isHome = pathname == "/";

  return (
      <header className={"main-header marketing-header " + (isHome ? "home " : "") + (mobOpen ? " open " : "") + (scrollY > 25 ? "scrolled" : "")}>
      <div className="container">
        <div className="left">
          <div className="brand">
            <Link to="/" className="desktop">
              <Logo/>
            </Link>
            <Link to="/" className="mobile">
              <Logo mobile/>
            </Link>
          </div>
        </div>
        <div className="mobile-toggle" onClick={() => setOpen(!mobOpen)}>
          <img src={`https://static.partnerlynx.com/icons/burger-menu.svg`} />
        </div>
        <div className="header-center">
          <nav>
            <Link to="/distributors" onClick={() => setOpen(!mobOpen)}>Search Distributors</Link>
            <Link to="/requests" onClick={() => setOpen(!mobOpen)}>Search Requests</Link>
            <div className="mobile-only">
              <Link to="/join" onClick={() => setOpen(!mobOpen)} className="btn ">
                Login
              </Link>
            </div>
            {/*<a href="https://distributors.aesthetixconnect.com">For Distributors</a>
            <a href="https://clients.aesthetixconnect.com">For Clients</a>*/}
          </nav>
        </div>
        <div className="header-actions">
          <Link to="/join" className="btn">
            Login
          </Link>
        </div>
      </div>
    </header>
  );
};
