import React from "react";
import {Link} from "react-router-dom";
import BHGImage from "../BHGImage";
import {RatingContainer} from "../RatingContainer";

export const ResultsList = props => {
    const {results = [], basePath = '/', loading, request = false} = props;
    return <div className="results-list">
        {loading && <div className="loading">Searching the database...</div>}
        <div className="row">
            {(results.length === 0 && !loading) && <div className="col-12">Sorry, we can't find any matching results.</div>}
            {results.map(result => {
                return <div key={result.id} className="col-md-6">
                    <Link to={basePath + result.id} onClick={() => window.fromDash = true}>
                        {request ? <RequestListItem {...result}/> : <DistributorListItem {...result}/>}
                    </Link>
                </div>
            })}
        </div>
    </div>
}

const DistributorListItem = props => {
    const {name, bio, rating, logo} = props;
    return <div className="result-item">
        <BHGImage src={logo} className="result-logo"/>
        <h5>{name}</h5>
        <RatingContainer rating={rating}/>
        <p>{bio?.length > 160 ? bio.substring(0, 160) + '...' : bio}</p>
    </div>
}

const RequestListItem = props => {
    const {title, customerName, anonymous} = props;
    return <div className="result-item">
        <h5>{title}</h5>
        <p>Posted by {anonymous === 1 ? 'anonymous' : customerName}</p>
    </div>
}
