import React, {useEffect} from "react";
import { SEO } from "../components/SEO";
import {Link} from "react-router-dom";
import {Logo} from "../components/Logo";
import {useToasts} from "react-toast-notifications";

export const Home = (props) => {
  const {addToast, removeAllToasts} = useToasts();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('body').scrollTo(0,0);
    removeAllToasts();
    let signUps = (new Date().getDay() + 1) * new Date().getDate();
    if(signUps > 105) signUps = 96 - (new Date().getDay() + 1);
    else if(signUps < 50) signUps = 52 + (new Date().getDay() + 1);
    setTimeout(() => addToast(`🔥 ${signUps} people have signed up in the last 7 days`, {autoDismissTimeout: 7000, placement: 'bottom-left', appearance: 'success', autoDismiss: true}), 3500)
  }, []);

  return (
    <div className="view home">
      <SEO title="The Hub For Aesthetics Professionals | Aesthetix Connect" slug="" />
      <section className="hero">
        <div className="container">
          <div className="hero-content">
            <h1>The Hub For Aesthetics Professionals</h1>
            <p>Your trusted platform to increase the connection between supply and demand
              in the beauty and aesthetics industry.</p>
            <form action="/distributors/">
              <div className="input-group">
                <input name="search" className="form-control mobile" placeholder="Search Distributors..."/>
                <input name="searchd" className="form-control desktop" placeholder="Search the Largest Aesthetics Distributor Database..."/>
                <button type="submit" className="btn btn-secondary">Search</button>
              </div>
              <Link className="btn btn-primary btn-block" to="/requests">Or see our currently open Client Requests</Link>
            </form>

          </div>
        </div>
      </section>
      <section className="high-level">
        <div className="container">
          <div className="intro">
            <h2>The Hub For Aesthetics Professionals</h2>
            <p>Aesthetix-Connect contains a comprehensive list of service providers nationally and internationally
              with automated match-making taking the guesswork out of any proposition.</p>
          </div>
          <div className="row">
            <div className="col-md-6 offset-md-3 offset-lg-0 col-lg-4">
              <Link to="/distributors">
                <div className="pl-card">
                  <div className="img-container im-1">
                    <span className="btn btn-tertiary">Search and Discover</span>
                  </div>
                  <p>View historical Distributor performance, post requests and automatically match with top Distributors.</p>
                </div>
              </Link>
            </div>
            <div className="col-md-6 offset-md-3 offset-lg-0 col-lg-4">
              <Link to="/join">
                <div className="pl-card">
                  <div className="img-container im-2">
                    <span className="btn btn-primary">Connect</span>
                  </div>
                  <p>Set up your profile and start connecting with the largest Distributor and Client database.</p>
                </div>
              </Link>
            </div>
            <div className="col-md-6 offset-md-3 offset-lg-0 col-lg-4">
              <Link to="/distributors">
                <div className="pl-card">
                  <div className="img-container im-3">
                    <span className="btn btn-secondary">Make a Deal</span>
                  </div>
                  <p>Once you set up your profile, start receiving automated notifications for requests matching your profile and make a deal.</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="how-it-works">
        <div className="container">
          <div className="row intro">
            <div className="col-md-5">
              <div className="img-container">
                <img src="//client-upload.bytehogs.com/ac/media/hiw-1.png"/>
              </div>

            </div>
            <div className="col-md-7">
              <h2>How It Works</h2>
              <img src="//client-upload.bytehogs.com/ac/illustrations/hiw-underline.svg"/>
              <p>
                <strong>For Clients -</strong> We adapted to make it as simple as possible for businesses to find and discover Distributors who can deliver expert-led services within any aesthetic sector. Our open marketplace means that businesses can post request’s, search and review Distributors and receive notifications once they match with a Distributor.
              </p>
              <p>
                <strong>For Distributors -</strong> We deliver with agility the easiest way to receive instant notifications for new requests which enter the market. Increase your online visibility of your value chain and sign up now.
              </p>
              <a href="https://clients.aesthetixconnect.com/register" className="btn btn-secondary">Join as a Client</a>
              <a href="https://distributors.aesthetixconnect.com/register" className="btn btn-primary">Join as a Distributor</a>
            </div>
          </div>
          <div className="row info">
            <div className="col-md-6 col-lg-5">
              <div className="pl-card first">
                <h3>Looking for a Distributor?</h3>
                <p>We believe you should be able to make an
                  informed decision on your choice of Distributor. Here are some fantastic reasons why</p>
                <a href="https://clients.aesthetixconnect.com/register" className="btn btn-primary">Join as a Client</a>
              </div>
            </div>
            <div className="col-md-6 col-lg-5 offset-lg-2">
              <Link to="/distributors">
                <div className="pl-card">
                  <div className="img-container im-1">
                    <span className="btn btn-secondary">Comprehensive Search</span>
                  </div>
                  <p>Search Distributors by name or by filtering by industry, product, region or more.</p>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-5">
              <Link to="/requests">
                <div className="pl-card">
                  <div className="img-container im-2">
                    <span className="btn btn-tertiary">Advanced Matchmaking</span>
                  </div>
                  <p>Submit your request and let our match-making do the magic. We automatically notify only relevant Distributor(s) for your request.</p>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-5 offset-lg-2">
              <Link to="/distributors">
                <div className="pl-card">
                  <div className="img-container im-3">
                    <span className="btn btn-primary">Choose with Confidence</span>
                  </div>
                  <p>View Distributor ratings from verified Client reviews so you can make an informed decision on who to work with.</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="distributor-hero">
        <div className="container">
          <Logo/>
          <h2>For Distributors</h2>
          <p>We connect you with specific customer requests and make your profile visible to hundreds of beauty experts seeking relevant products Globally.</p>
          <a href="https://distributors.aesthetixconnect.com/register" className="btn btn-primary">Join as a Distributor</a>
        </div>
      </section>
      <section className="distributor-steps">
        <div className="container">
          <div className="row">
            <Link to="/join" className="col-md-6 col-lg-4 s-1">
              <img src="//client-upload.bytehogs.com/ac/media/step-1.png"/>
              <div>
                <p>Set up your profile</p>
              </div>
            </Link>
            <Link to="/requests" className="col-md-6 col-lg-4 s-2">
              <img src="//client-upload.bytehogs.com/ac/media/step-2.png"/>
              <div>
                <p>Browse hundreds of open requests</p>
              </div>
            </Link>
            <Link to="/distributors" className="col-md-6 col-lg-4 s-3">
              <img src="//client-upload.bytehogs.com/ac/media/step-3.png"/>
              <div>
                <p>Improve your rating through reviews</p>
              </div>
            </Link>
            <Link to="/join" className="col-md-6 col-lg-4 s-4">
              <img src="//client-upload.bytehogs.com/ac/media/step-4.png"/>
              <div>
                <p>Receive automated notifications</p>
              </div>
            </Link>
            <Link to="/join" className="col-md-6 col-lg-4 s-5">
              <img src="//client-upload.bytehogs.com/ac/media/step-5.png"/>
              <div>
                <p>Invite your full team</p>
              </div>
            </Link>
            <Link to="/join" className="col-md-6 col-lg-4 s-6">
              <img src="//client-upload.bytehogs.com/ac/media/step-6.png"/>
              <div>
                <p>Send proposals directly to customers</p>
              </div>
            </Link>
          </div>
        </div>
      </section>
      <section className="bottom-hero">
        <div className="container">
          <h2>Start discovering new opportunities today.</h2>
          <a href="https://clients.aesthetixconnect.com/register" className="btn btn-secondary">Join as a Client</a>
          <a href="https://distributors.aesthetixconnect.com/register" className="btn btn-secondary">Join as a Distributor</a>
        </div>

      </section>

      {/*<section className="section-one">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>the hub for aesthetics professionals</h1>
              <p>
                Your trusted platform to increase the connection between supply and demand in the beauty and aesthetics industry.
              </p>
              <div className="buttons">
                <a href="https://clients.aesthetixconnect.com" className="btn btn-outline-primary">Join as a Client</a>
                <a href="https://distributors.aesthetixconnect.com" className="btn btn-outline-primary">Join as a Distributor</a>
              </div>
            </div>
            <div className="col-md-6">
              <img src={test} alt="top" />
            </div>
            <div className="col-12">
              <div className="pl-card">
                <h3>Search the Largest Aesthetics Distributor Database</h3>
                <form action="/distributors/">
                  <input name="search" className="form-control"/>
                  <button type="submit" className="btn btn-block btn-secondary">Search Distributors</button>
                </form>
                <Link className="btn btn-link" to="/requests">Or see our currently open Requests</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-two">
        <div className="container">
          <div className="success">
            <h2>Transform Every Opportunity</h2>
            <p>
              Aesthetix-Connect contains a comprehensive list of service providers nationally and internationally with automated match-making taking the guesswork out of any proposition.
            </p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <HomeCard
                title="Search and Discover"
                content="View historical distributor performance, post requests and automatically match with top distributors."
                image={ACSearch}
              />
            </div>
            <div className="col-md-4">
              <HomeCard
                title="Connect"
                content="Set up your profile and start connecting with the largest distributor and client database."
                image={ACConnect}
              />
            </div>
            <div className="col-md-4">
              <HomeCard
                title="Make a Deal"
                content="Set up your profile and start receiving automated notifications for requests matching your profile."
                image={ACDeal}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-three">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-7 works">
              <h2>How It Works</h2>
              <p>
                <strong>For Clients</strong> - We adapted to make it as simple as possible for businesses to find and discover distributors who can deliver expert-led services within any aesthetic sector.
                Our open marketplace means that businesses can post request’s, search and review distributors and receive notifications once they match with a distributor.
              </p>
              <p>
                <strong>For Distributors</strong> - We deliver with agility the easiest way to receive instant notifications for new requests which enter the market. Increase your online visibility of your value chain and sign up now.
              </p>
              <div>
                <a href="https://clients.aesthetixconnect.com" className="btn btn-outline-primary">Join as a Client</a>
                <a href="https://distributors.aesthetixconnect.com" className="btn btn-primary">Join as a Distributor</a>
              </div>
            </div>
            <div className="col">
              <img src="https://client-upload.bytehogs.com/ac/media/ac-main-image.svg" alt="mission" className="mission-image"/>
            </div>
          </div>
        </div>

      </section>
      <section className="section-four">
        <div className="row no-gutters">
          <div className="col-md-6">
            <div className="yellow">
              <h3>Looking for a Distributor?</h3>
              <p>
                We believe you should be able to make an informed decision on your choice of distributor. Here are some fantastic reasons why
              </p>
              <a href="https://clients.aesthetixconnect.com" className="btn btn-primary">Join as a Client</a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="coral">
              <img src={PLSearch} alt="Search"/>
              <h3>Comprehensive Search</h3>
              <p>
                Search distributors by name
                or by filtering by industry, product,
                region or more.
              </p>
            </div>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-md-6">
            <div className="orange">
              <img src={PLMatch} alt="Match"/>
              <h3>Advanced Matchmaking</h3>
              <p>
                Submit your request and let our match-making do the magic. We automatically notify only relevant distributor(s) for your request.
              </p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="blue">
              <img src={PLConf} alt="Confidence"/>
              <h3>Choose with Confidence</h3>
              <p>
                View distributor ratings from verified client testimonials so you can make an informed decision on who to work with.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="sectionsix">
        <div className="container">
          <div className="text-center">
            <h2>For Distributors</h2>
            <p>
              We connect you with relevant requests and make your profile visible to
              hundreds of companies looking for services.
            </p>
            <a href="https://distributors.aesthetixconnect.com" className="btn btn-primary">Join as a Distributor</a>
          </div>
          <div className="row">
            <div className="col-md-4">
              <HomeCard
                  title="Set up your profile"
                  content={null}
                  image={PLProfile}
              />
            </div>
            <div className="col-md-4">
              <HomeCard
                  title="Browse hundreds of open requests"
                  content={null}
                  image={PLOpen}
              />
            </div>
            <div className="col-md-4">
              <HomeCard
                  title="Improve your rating through testimonials"
                  content={null}
                  image={PLTest}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <HomeCard
                  title="Receive automated notifications"
                  content={null}
                  image={PLAlerts}
              />
            </div>
            <div className="col-md-4">
              <HomeCard
                  title="Invite your full team"
                  content={null}
                  image={PLInvite}
              />
            </div>
            <div className="col-md-4">
              <HomeCard
                  title="Send proposals directly to customers"
                  content={null}
                  image={PLProp}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bottomsec">
        <h2>Start discovering new opportunities today.</h2>
        <a href="https://clients.aesthetixconnect.com" className="btn btn-secondary">Join as a Client</a>
        <a href="https://distributors.aesthetixconnect.com" className="btn btn-secondary">Join as a Distributor</a>
      </section>*/}
    </div>
  );
};
