import React, {useState} from "react";

export const FilterGroup = props => {
    const {title, options = [], active = [], handleChange} = props;
    const [open, setOpen] = useState(false);

    const handleClick = id => {
        let newData = [...active];
        if(newData.includes(id)) newData.splice(newData.indexOf(id), 1);
        else newData.push(id);
        handleChange(newData);
    }

    const handleSelectAll = () => {
        let newData = options.map(x => x.id);
        handleChange(newData);
    }

    return <div className="filter-group">
        <div className="title">
            <label>{title}</label>
            <span className="select-all" onClick={handleSelectAll}>Select all</span>
        </div>
        <div className="filter-options">
            {options.length === 0 && <div className="mb-2">Loading...</div>}
            {options.map((opt, i) => {
                const {id, name} = opt;
                if(!open && i > 3) return null
                const isActive = active.includes(opt.id);
                return <div key={id} className="filter-option" onClick={() => handleClick(id)}>
                    <div className={"opt-checkbox " + (isActive ? "active" : "")}/>
                    <span>{name}</span>
                </div>
            })}
            <div onClick={() => setOpen(!open)} className={"options-toggle " + (open ? "open" : "")}>
                <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0.249977L9.50004 10.75L19 0.249977H0Z" fill="black"/>
                </svg>
            </div>
        </div>
    </div>
}
