import React, {useEffect, useState} from "react";
import {SEO} from "../components/SEO";
import {ResultsList} from "../components/dashboard/ResultsList";
import axios from "axios";
import {STAGE} from "../getStage";
import {FiltersList} from "../components/dashboard/FiltersList";
import {useToasts} from "react-toast-notifications";

const arrayCleaner = arr => arr.filter(x => (x !== 0) && x);

export const SearchDistributors = props => {
    const {addToast, removeAllToasts} = useToasts();
    const {location} = props;
    const searchParams = new URLSearchParams(location.search)

    const reg = arrayCleaner(searchParams?.get("regions")?.split(',')?.map(Number) || []);
    const prod = arrayCleaner(searchParams?.get("sectors")?.split(',')?.map(Number) || []);
    const serv = arrayCleaner(searchParams?.get("services")?.split(',')?.map(Number) || []);
    const searchQuery = searchParams?.get("search") || searchParams?.get("searchd") ||"";

    const [state, setState] = useState({
        regions: reg,
        sectors: prod,
        services: serv
    });

    const {
        regions,
        sectors,
        services
    } = state;
    const [results, setResults] = useState([]);
    const [query, setQuery] = useState(searchQuery);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    const search = async () => {
        //if(!loading) {
            try {
                setLoading(true);
                const response = await axios.post(`https://${STAGE}.bytehogs.com/ac-m-be/distributors`, JSON.stringify({
                    sectors,
                    services,
                    regions,
                    operation: "search"
                }));
                //setState({...state, loaded: true, loading: false});
                setLoaded(true);
                setLoading(false);
                if(response.status === 200) {
                    setResults(response.data.distributors || []);
                    props.history.push({
                        pathname: '/distributors',
                        search: "?" + new URLSearchParams({
                            regions,
                            sectors,
                            services}).toString()
                    });
                }
            } catch (e) {
                setLoaded(true);
                setLoading(false);
                console.log("e", e);
            }
        //}
    }

    const searchByName = async () => {
        //if(!loading) {
            try {
                setLoading(true);
                const response = await axios.post(`https://${STAGE}.bytehogs.com/ac-m-be/distributors`, JSON.stringify({
                    query,
                    operation: "searchByName"
                }));
                setState({
                    regions: [],
                    sectors: [],
                    services: [],
                });
                setLoaded(true);
                setLoading(false);
                if(response.status === 200) {
                    setResults(response.data.distributors || []);
                    props.history.push({
                        pathname: '/distributors',
                        search: "?" + new URLSearchParams({search: query}).toString()
                    });
                }
            } catch (e) {
                setLoaded(true);
                setLoading(false);
                console.log("e", e);
            }
        //}
    }

    const handleQueryChange = e => {
        setQuery(e.target.value);
    }

    const handleFilterChange = (data, key) => {
        let newState = {...state};
        newState[key] = data;
        setState(newState);
        setQuery("");
    }

    useEffect(() => {
        if(query.length === 0) {
            search()
        }
    }, [
        regions,
        sectors,
        services]);

    useEffect(() => {
        if(query && query.length >= 2) {
            searchByName();
        } else if((!query || query.length === 0) && loaded) {
            search()
        }
    }, [query]);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.querySelector('body').scrollTo(0,0);
        removeAllToasts()
        let views = (new Date().getHours() + 1) * new Date().getDate();
        if(views > 130) views = 127 - (new Date().getHours() + 1);
        else if(views < 50) views = 48 + (new Date().getHours() + 1);
        setTimeout(() => addToast(`👀 ${views} people have searched in the last hour`, {autoDismissTimeout: 7000, placement: 'bottom-left', appearance: 'success', autoDismiss: true}), 3500)

    }, []);

    return <div className="view dashboard search-distributors">
        <SEO title="Search Distributors on Aesthetix Connect" slug="/distributors" />
        <div className="container">
            <div className="intro">
                <h1>Search our Database of Distributors</h1>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <FiltersList activeFilters={{
                        regions,
                        sectors,
                        services,
                    }}
                     handleChange={handleFilterChange}/>
                </div>
                <div className="col-md-7 offset-md-1">
                    <div className="search">
                        <label>Find a Distributor by Name</label>
                        <input type="text" className="form-control" value={query} onChange={handleQueryChange}/>
                    </div>
                    <ResultsList results={results} basePath="/distributors/" loading={loading}/>
                </div>
            </div>
        </div>
    </div>
}
