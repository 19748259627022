import React from 'react';
import Routing from "./components/Routing";
import "./assets/sass/index.scss";
import {ToastProvider} from "react-toast-notifications";
import {SalesPop} from "./components/SalesPop";

function App() {
  return <ToastProvider components={{Toast: SalesPop}} placement="bottom-left"><Routing/></ToastProvider>
}

export default App;
