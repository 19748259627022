import React from "react";
import Modal from "react-modal";
import {PREFIX} from "../../getStage";

export const AuthPromptModal = props => {
        const {isOpen, close, base = 'partners'} = props;

        return <Modal
            isOpen={isOpen}
            onRequestClose={close}
            className={"modal animated auth-prompt"}
            overlayClassName="modal-overlay"
            contentLabel="Auth Prompt Modal">
                <div>
                        <h3>Account Required</h3>
                        <p>You need to be logged in to complete this operation.</p>
                        <a href={"https://"+ PREFIX +base+".aesthetixconnect.com/login"} className="btn btn-secondary">Login</a>
                        {/*<button className="btn btn-link" onClick={close}>Close</button>*/}
                </div>
        </Modal>
}
