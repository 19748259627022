import React from "react";

const toastStates = () => ({
    entering: { transform: 'translate3d(0, 120%, 0)' },
    entered: { transform: 'translate3d(0,0,0)' },
    exiting: { transform: 'scale(0.66)', opacity: 0 },
    exited: { transform: 'scale(0.66)', opacity: 0 },
});

export const SalesPop = ({children, transitionDuration, transitionState, ...props}) => (
    <div className="sales-pop" style={{
        transition: `transform ${transitionDuration}ms cubic-bezier(0.2, 0, 0, 1), opacity ${transitionDuration}ms, margin ${transitionDuration/2}ms`,
        ...toastStates()[transitionState],
    }} {...props}>
        {children}
    </div>
);
