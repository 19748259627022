import React from "react";
import {withRouter} from "react-router-dom";

const BackToDashboard = props => {
    const {history, backPath = '/distributors'} = props;

    const handleBackClick = () => (window.fromDash) ? history.goBack() : history.push(backPath);

    return <div className="back-to-dash">
        <span onClick={handleBackClick}>
            <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0.249977L9.50004 10.75L19 0.249977H0Z" fill="black"/>
            </svg>
            Back to Search
        </span>
    </div>
}

export default withRouter(BackToDashboard);
